
import { defineComponent } from "vue";
import { mapGetters, mapActions, mapState } from "vuex";
import Button from "primevue/button";
import InlineMessage from 'primevue/inlinemessage'
import SalesService from "@/services/SalesService"
import SendOrderDialog from "@/components/UI/SendOrderDialog.vue";

const salesService = new SalesService(process.env.VUE_APP_ABSTRACTION_API);

export default defineComponent({
  name: "Confirmation",
  components: {
    Button,
    InlineMessage,
    SendOrderDialog,
  },
  computed: {
    ...mapState(["session", "pos"]),
    ...mapGetters({
      getLastOrderId: "pos/getLastOrderId",
      getPDFS: "pos/getPDFS",
      getLoadingPDFs: "pos/getLoadingPDFs",
    }),
  },

  data() {
    return {
      isPDFLoading: false,
      showEmailPDFDialog: false,
      emailLoading: false,
    };
  },

  created() {
    if (!this.getLastOrderId) {
      this.$router.push("/sales");
    }
  },

  methods: {
    ...mapActions({
      clearPOS: "pos/clearPOS",
      getOrderPDF: "pos/getOrderPDF",
      addNotification: "notification/add",
    }),
    dialogTitle() {
      return `Send ${this.getLastOrderId} order PDF`
    },
    handleContinueShopping() {
      this.$router.push('/sales')
      this.clearPOS();
    },
    async handleGetOrderPDF(orderId: string) {
      this.isPDFLoading = true;
      await this.getOrderPDF({recordId: orderId})
      this.isPDFLoading = false;

    },
    orderPDFIcon(id: string) {
      let downloaded: Array<any> = this.getPDFS
      let downloading: boolean = this.getLoadingPDFs.find((i: string) => i === id) !== undefined
      return {
        "pi pi-download col-12":
          downloaded.find((i: any) => i.id === id) === undefined &&
          !downloading,
        "pi pi-spin pi-spinner col-12": downloading,
        "pi pi-file-pdf col-12":
          downloaded.find((i: any) => i.id === id) !== undefined &&
          !downloading,
      };
    },
    handleEmailPDF() {
      this.showEmailPDFDialog = true;
    },
    handleCloseEmailDialog() {
      this.showEmailPDFDialog = false;
    },
    handleSendEmail(data: any) {
      this.emailLoading = true
      this.getOrderPDF({recordId: this.getLastOrderId, email: data.emailToSend})
      .finally(() => {
        this.handleCloseEmailDialog();
        this.emailLoading = false
      })
    },
  },
});
